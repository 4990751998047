
@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');

/* ---------- Globals ---------------------*/
html{
    scroll-behavior: smooth;
}

.skewedBoth{
    content: '';
    width: 100%;
    height: 100%;
    background: inherit;
    z-index: -1;
    top: 0;
    transform-origin: left top;
    transform: skewY(-3deg);
}
.skewedBothContent{
    transform: skewY(3deg);
}

.skewedBoth:after {
    bottom:0;
    transform-origin: left bottom;
    transform: skewY(3deg);
}
.skewedLeftBottom{
    content: '';
    width: 100%;
    height: 100%;
    background: inherit;
    z-index: -1;
    bottom: 0;
    transform-origin: right bottom;
    transform: skewY(-3deg);
}
.skewedLeftBottomContent{
    transform: skewY(3deg);
}
.skewedLeftTop{
    content: '';
    width: 100%;
    height: 100%;
    background: inherit;
    z-index: -1;
    bottom: 0;
    transform-origin: right top;
    transform: skewY(3deg);
}
.skewedLeftTopContent{
    transform: skewY(-3deg);
}
.skewedLeft:before {

}
.skewedRight{}
.skewedRight:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background: inherit;
    z-index: -1;
    bottom: 0;
    transform-origin: left bottom;
    transform: skewY(3deg);
}
.skewedRightTop{
    content: '';
    width: 100%;
    height: 100%;
    background: inherit;
    z-index: -1;
    bottom: 0;
    transform-origin: left top;
    transform: skewY(-3deg);
}
.skewedRightContent{
    transform: skewY(3deg);
}

body{
    width: 100%;
}

/* ------------BACKGROUNDS ----------------*/

.bgPurple{
    background-color: rgb(57, 10, 79);

}
.bgYellow{
    background-color: #cccc00;
}
.bgWhite{
    background-color: white;
}
.font-type{
    font-family: 'Righteous',cursive;
}
.font-color{
    color: #bda103;
}

/* --------- NAVBAR --------------*/

.sticky-nav {
    position: sticky;
    top: 0;
    height: 13rem;
    margin: -35px;
    overflow: hidden;
}
.navContent{
}

/* ---------------- HEADER --------------*/

.intro {
    display: table;
    width: 100%;
    padding: 0;
    background: url("exampleLanding.jpg") center center no-repeat;
    background-color: rgba(91, 12, 122, 0.89);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
    overflow: hidden;
    height: 150%;
}
.intro .overlay {
    background: rgba(84, 5, 98, 0.5);
}
.intro h1 {
    font-family: "Righteous", sans-serif;
    color: #fff;
    font-size: 82px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 10px;
    overflow: hidden;
}
.intro h1 span {
    font-weight: 800;
    color: #5ca9fb;
}
.intro p {
    color: #fff;
    font-size: 22px;
    font-weight: 300;
    line-height: 30px;
    margin: 0 auto;
    margin-bottom: 60px;
    padding-right: 6.6rem;
    overflow: hidden;
}
header .intro-text {
    padding-bottom: 7rem;
    text-align: center;
    margin-left: 2rem;
}
header .intro-text a{
    margin-right: 10.5rem;
    overflow: hidden;
}

/* ------------- SCROLLING ARROW -------------*/

*, *:before, *:after {
    -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
}


.mouse_scroll {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 24px;
    height: 100px;

}


.m_scroll_arrows
{
    display: block;
    width: 5px;
    height: 5px;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);

    border-right: 2px solid #a99202;
    border-bottom: 2px solid #a99202;
    margin: 0 0 3px 4px;

    width: 16px;
    height: 16px;
}


.unu
{
    margin-top: 1px;
}

.unu, .doi, .trei
{
    -webkit-animation: mouse-scroll 1s infinite;
    -moz-animation: mouse-scroll 1s infinite;
    animation: mouse-scroll 1s infinite;

}

.unu
{
    -webkit-animation-delay: .1s;
    -moz-animation-delay: .1s;
    -webkit-animation-direction: alternate;

    animation-direction: alternate;
}

.doi
{
    -webkit-animation-delay: .2s;
    -moz-animation-delay: .2s;
    -webkit-animation-direction: alternate;

    animation-delay: .2s;
    animation-direction: alternate;

    margin-top: -6px;
}

.trei
{
    -webkit-animation-delay: .3s;
    -moz-animation-delay: .3s;
    -webkit-animation-direction: alternate;

    animation-delay: .3s;
    animation-direction: alternate;


    margin-top: -6px;
}

.mouse {
    height: 42px;
    width: 24px;
    border-radius: 14px;
    transform: none;
    border: 2px solid #a99202;
    top: 170px;
}

.wheel {
    height: 5px;
    width: 2px;
    display: block;
    margin: 5px auto;
    background: #a99202;
    position: relative;

    height: 4px;
    width: 4px;
    border: 2px solid #a99202;
    -webkit-border-radius: 8px;
    border-radius: 8px;
}

.wheel {
    -webkit-animation: mouse-wheel 0.6s linear infinite;
    -moz-animation: mouse-wheel 0.6s linear infinite;
    animation: mouse-wheel 0.6s linear infinite;
}

@-webkit-keyframes mouse-wheel{
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(6px);
        -ms-transform: translateY(6px);
        transform: translateY(6px);
    }
}
@-moz-keyframes mouse-wheel {
    0% { top: 1px; }
    25% { top: 2px; }
    50% { top: 3px;}
    75% { top: 2px;}
    100% { top: 1px;}
}
@-o-keyframes mouse-wheel {

    0% { top: 1px; }
    25% { top: 2px; }
    50% { top: 3px;}
    75% { top: 2px;}
    100% { top: 1px;}
}
@keyframes mouse-wheel {

    0% { top: 1px; }
    25% { top: 2px; }
    50% { top: 3px;}
    75% { top: 2px;}
    100% { top: 1px;}
}

@-webkit-keyframes mouse-scroll {

    0%   { opacity: 0;}
    50%  { opacity: .5;}
    100% { opacity: 1;}
}
@-moz-keyframes mouse-scroll {

    0%   { opacity: 0; }
    50%  { opacity: .5; }
    100% { opacity: 1; }
}
@-o-keyframes mouse-scroll {

    0%   { opacity: 0; }
    50%  { opacity: .5; }
    100% { opacity: 1; }
}
@keyframes mouse-scroll {

    0%   { opacity: 0; }
    50%  { opacity: .5; }
    100% { opacity: 1; }
}


/* ------------- Articles ---------- */

.articlesBackground{
    width: 100%;
    transform: rotate(2deg);
    padding: 3em;
    margin-top: -1.5rem;
    margin-left: -1.4rem !important;
    overflow: hidden !important;
}
.articles{
    transform: rotate(-2deg);
    margin-left: 6rem;
}
.article{
    padding: 1rem;
    width: 65%;
    font-family: Righteous, sans-serif;
}
.hoofding{
    text-decoration: underline #bda103;
}

/*---------------Gallery-------------------------*/

.galleryThumbnails{
    height: 11rem;
    width: 11rem;
}

.footerText{
    position: relative;
    font-size: 0.8rem;
    top: 2.5rem;
    right: 0;
    left: 0;
}

.loader{
    background-color: rgba(255, 255, 255, 0.85);
    z-index: 99;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 120%;
    width: 100vw;
  }